import {Language as LanguageModel} from 'models';
import languageIcon from './language-icon.svg';
import {Block} from '..';
import {useState} from 'react';
import {LanguagePicker} from './components/languagePicker';
import style from './style.module.css';
import {DELIVERY_AND_PAYMENTS_PATHNAME} from "const";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const GET_LANGUAGE: Record<LanguageModel, 'RU' | 'EN'> = {
    en: 'EN',
    ru: 'RU',
};

const Language = ({
                      language,
                      className,
                      onChangeLanguage,
                  }: {
    language: LanguageModel;
    className: string;
    onChangeLanguage: (language: LanguageModel) => void;
}) => {
    const [open, setOpen] = useState(false);

    return (
        <>

            <Block
                icon={languageIcon}
                value={GET_LANGUAGE[language]}
                {...{onClick: () => setOpen(!open), className}}
            />
            <LanguagePicker
                currentLanguage={language}
                onChangeLanguage={onChangeLanguage}
                open={open}
                onClose={() => setOpen(false)}
            />


        </>
    );
};

export default Language;
