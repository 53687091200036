import clsx from 'clsx';
import SorryIcon from 'images/img/modal/sorry.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { FEButton } from 'ui-kit/components';
import style from './Empty.module.css';

export const Empty = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className={style.container}>
      <img className={style.icon} src={SorryIcon} alt='' />
      <h1 className={clsx('title', style.title)}>{t('Ничего нету')}</h1>
      <p className={clsx('text-2', style.message)}>
        {t('Ты можешь сохранить карту во время оформления заказа')}
      </p>
      <FEButton className={style.button} onClick={() => navigate('/')}>
        {t('Вернуться в каталог')}
      </FEButton>
    </div>
  );
};
