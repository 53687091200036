import style from './PaymentType.module.css';
import clsx from 'clsx';
import { PaymentType as PaymentTypeModel } from './types';
import { OrderType } from 'models';
import {
  IMG_IC_24_CASH,
  IMG_IC_24_CREDIT_CARD,
  IMG_IC_24_CARD,
  IMG_IC_24_SBP,
} from 'images';
import { useTranslation } from 'react-i18next';
import { FERadioButton } from 'ui-kit/components';

const GET_PAYMENT_TYPE_IMAGE: Record<
  Exclude<PaymentTypeModel, 'SAVED'>,
  string
> = {
  CARD: IMG_IC_24_CREDIT_CARD,
  CASH: IMG_IC_24_CASH,
  TERMINAL: IMG_IC_24_CARD,
  SBP: IMG_IC_24_SBP,
};

const GET_PAYMENT_TYPE_LABEL: Record<
  OrderType,
  Record<Exclude<PaymentTypeModel, 'SAVED'>, string>
> = {
  COURIER: {
    CARD: 'Картой онлайн',
    CASH: 'Наличными',
    TERMINAL: 'Картой курьеру',
    SBP: 'СБП',
  },
  GO_TO_PLACE: {
    TERMINAL: 'Картой при получении',
    CARD: 'Картой онлайн',
    CASH: 'Наличными',
    SBP: 'СБП',
  },
  IN_PLACE: {
    TERMINAL: 'Картой при получении',
    CARD: 'Картой онлайн',
    CASH: 'Наличными',
    SBP: 'СБП',
  },
};

//TODO: переводы
export const PaymentType = ({
  paymentType,
  orderType,
  isActive,
  onClick,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div
      is-active={isActive.toString()}
      className={style.paymentType}
      onClick={onClick}
    >
      <img
        className={style.icon}
        src={GET_PAYMENT_TYPE_IMAGE[paymentType]}
        alt=''
      />
      <div className={style.radioContainer}>
        <FERadioButton active={isActive} />
      </div>
      <p className={clsx('text-2', style.label)}>
        {t(GET_PAYMENT_TYPE_LABEL[orderType][paymentType])}
      </p>
    </div>
  );
};

type Props = {
  paymentType: Exclude<PaymentTypeModel, 'SAVED'>;
  onClick: () => void;
  isActive: boolean;
  orderType: OrderType;
};
