import { PROFILE_PATHNAME, SAVED_CARDS_PATHNAME } from 'const';
import { useEffect, useInsertionEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useMediaQuery } from 'ui-kit/hooks';
import { NavigateButton } from '../NavigateButton';
import { Card } from './Card';
import { useCards } from './lib/useCards';
import style from './SavedCardsCard.module.css';

export const SavedCardsCard = ({ className, onChangeWithCards }: Props) => {
  const internalCardsCallback = useRef(onChangeWithCards);

  useInsertionEffect(() => {
    internalCardsCallback.current = onChangeWithCards;
  }, [onChangeWithCards]);

  const [withCards, setWithCards] = useState(false);

  const { cards } = useCards();

  useEffect(() => {
    if (cards.length) {
      setWithCards(true);
      internalCardsCallback.current(true);
    } else {
      setWithCards(false);
      internalCardsCallback.current(false);
    }
  }, [cards]);

  const is500 = useMediaQuery('(min-width: 500px)');

  const { t } = useTranslation();

  const navigate = useNavigate();

  const savedCardsLink = `/${PROFILE_PATHNAME}/${SAVED_CARDS_PATHNAME}`;

  return (
    <div
      with-cards={withCards.toString()}
      className={className}
      onClick={() => navigate(savedCardsLink)}
    >
      <div className={style.header}>
        <p className={is500 ? 'title' : 'headline'}>{t('Банковские карты')}</p>
        <NavigateButton
          className={style.navigateButton}
          onClick={() => navigate(savedCardsLink)}
        />
      </div>
      {!!cards.length && (
        <Card
          className={style.card}
          lastFour={cards[0].lastFour}
          type={cards[0].type}
        />
      )}
    </div>
  );
};

type Props = {
  className: string;
  onChangeWithCards: (value: boolean) => void;
};
