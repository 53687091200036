import FeatSDKV3, {
  OrdersCreateRequestV3,
  ProductsSearchResponseV3,
} from 'api/version3';
import FeatSDKV1, {
  CardTransactionResponseV1,
  GetCustomerResponseV1,
} from 'api/version1';
import { createEffect } from 'effector';
import FeatSDKV4, {
  OrdersValidateRequestV4,
  OrdersValidateResponseV4,
} from 'api/version4';
import {
  ValidateFxModel,
  CreateOrderFxModel,
  DishToValidateModel,
  ConstructorToValidateModel,
  setPromocodeFxModel,
  AddDishToCartFxModel,
  LastOrder,
  AddConstructorToCartFxModel,
  PromotionProduct,
} from './entities';
import { BaseErrorResponse } from 'api/base';
import {
  hasSameConstructor,
  hasSameDish,
  mapConstructorsFromCartToValidate,
  mapDishesFromCartToValidate,
} from './model';
import { Order } from 'domains/profile';
import { Address, OrderType, PaymentType, ProductType } from 'models';
import { v4 } from 'uuid';
import FeatSDKV2, {
  GetDeliveryZoneTimeIntervalsResponseV2,
  GetRestaurantsTimeIntervalsResponseV2,
} from 'api/version2';
import { DeliveryZone, Restaurant, TimeIntervals } from 'domains/cartography';
import { isInSchedule } from 'components';
import { CatalogCategory } from 'domains/catalog';

export const getRepeatedOrderProductsFx = createEffect(
  async ({ order, catalogId }: { order: Order; catalogId: string }) => {
    const { dishes: orderDishes, constructors: orderConstructors } = order;

    const { dishes, constructors } = await FeatSDKV3.products.search({
      catalogId,
      products: [
        ...orderDishes.map((dish) => ({
          id: dish.product.id,
          type: 'DISH' as ProductType,
        })),
        ...orderConstructors.map((constructor) => ({
          id: constructor.product.id,
          type: 'DISH' as ProductType,
        })),
      ],
    });

    const dishesByIdObject = Object.fromEntries(
      dishes.map((dish) => [dish.id, dish]),
    );

    const actualDishes = orderDishes.filter((actualDish) => {
      const sameIdDish: ProductsSearchResponseV3.Dish | undefined =
        dishesByIdObject[actualDish.product.id] || undefined;

      if (sameIdDish === undefined) return false;
      else {
        const sameIdBase = sameIdDish.bases.find(
          (base) => base.id === actualDish.base.id,
        );
        if (sameIdBase === undefined) return false;
        else {
          if (
            !actualDish.base.switchGroups
              .map((switchgroup) => switchgroup.id)
              .every((switchGroup) =>
                sameIdBase.switchGroups
                  .map((switchGroup) => switchGroup.id)
                  .includes(switchGroup),
              )
          )
            return false;
          if (
            !actualDish.base.addonGroups
              .map((addonGroup) => addonGroup.id)
              .every((addonGroup) =>
                sameIdBase.addonGroups
                  .map((addonGroup) => addonGroup.id)
                  .includes(addonGroup),
              )
          )
            return false;
          if (
            !actualDish.base.selectorGroups
              .map((selectorGroup) => selectorGroup.id)
              .every((selectorGroup) =>
                sameIdBase.selectorGroups
                  .map((selectorGroup) => selectorGroup.id)
                  .includes(selectorGroup),
              )
          )
            return false;

          const sameIdBaseSwitchGroupsObject = Object.fromEntries(
            sameIdBase.switchGroups.map((switchGroup) => [
              switchGroup.id,
              switchGroup,
            ]),
          );

          if (
            !actualDish.base.switchGroups
              .map((actualSwitchGroup) => {
                const sameSwitchGroup =
                  sameIdBaseSwitchGroupsObject[actualSwitchGroup.id];
                return actualSwitchGroup.modifiers
                  .map((modifier) => modifier.id)
                  .every((modifier) =>
                    sameSwitchGroup.modifiers
                      .map((modifier) => modifier.id)
                      .includes(modifier),
                  );
              })
              .every((hasModifier) => hasModifier)
          )
            return false;

          const sameIdBaseSelectorGroupsObject = Object.fromEntries(
            sameIdBase.selectorGroups.map((selectorGroup) => [
              selectorGroup.id,
              selectorGroup,
            ]),
          );

          if (
            !actualDish.base.selectorGroups
              .map((actualSelectorGroup) => {
                const sameSelectorGroup =
                  sameIdBaseSelectorGroupsObject[actualSelectorGroup.id];
                return actualSelectorGroup.modifiers
                  .map((modifier) => modifier.id)
                  .every((modifier) =>
                    sameSelectorGroup.modifiers
                      .map((modifier) => modifier.id)
                      .includes(modifier),
                  );
              })
              .every((hasModifier) => hasModifier)
          )
            return false;

          const sameIdBaseAddonGroupsObject = Object.fromEntries(
            sameIdBase.addonGroups.map((addonGroup) => [
              addonGroup.id,
              addonGroup,
            ]),
          );

          if (
            !actualDish.base.addonGroups
              .map((actualAddonGroup) => {
                const sameAddonGroup =
                  sameIdBaseAddonGroupsObject[actualAddonGroup.id];
                return actualAddonGroup.modifiers
                  .map((modifier) => modifier.id)
                  .every((modifier) =>
                    sameAddonGroup.modifiers
                      .map((modifier) => modifier.id)
                      .includes(modifier),
                  );
              })
              .every((hasModifier) => hasModifier)
          )
            return false;
          return true;
        }
      }
    });

    const constructorsByIdObject = Object.fromEntries(
      constructors.map((constructor) => [constructor.id, constructor]),
    );

    const actualConstructors = orderConstructors.filter((actualConstructor) => {
      const sameIdConstructor:
        | ProductsSearchResponseV3.Constructor
        | undefined = constructorsByIdObject[actualConstructor.product.id];

      if (sameIdConstructor === undefined) return false;
      else {
        const sameIdBase = sameIdConstructor.bases.find(
          (base) => base.id === actualConstructor.base.id,
        );
        if (sameIdBase === undefined) return false;
        else {
          if (
            !actualConstructor.base.addonGroups
              .map((addonGroup) => addonGroup.id)
              .every((addonGroup) =>
                sameIdBase.addonGroups
                  .map((addonGroup) => addonGroup.id)
                  .includes(addonGroup),
              )
          )
            return false;
          if (
            !actualConstructor.base.selectorGroups
              .map((selectorGroup) => selectorGroup.id)
              .every((selectorGroup) =>
                sameIdBase.selectorGroups
                  .map((selectorGroup) => selectorGroup.id)
                  .includes(selectorGroup),
              )
          )
            return false;

          const sameIdBaseSelectorGroupsObject = Object.fromEntries(
            sameIdBase.selectorGroups.map((selectorGroup) => [
              selectorGroup.id,
              selectorGroup,
            ]),
          );

          if (
            !actualConstructor.base.selectorGroups
              .map((actualSelectorGroup) => {
                const sameSelectorGroup =
                  sameIdBaseSelectorGroupsObject[actualSelectorGroup.id];
                return actualSelectorGroup.modifiers
                  .map((modifier) => modifier.id)
                  .every((modifier) =>
                    sameSelectorGroup.modifiers
                      .map((modifier) => modifier.id)
                      .includes(modifier),
                  );
              })
              .every((hasModifier) => hasModifier)
          )
            return false;

          const sameIdBaseAddonGroupsObject = Object.fromEntries(
            sameIdBase.addonGroups.map((addonGroup) => [
              addonGroup.id,
              addonGroup,
            ]),
          );

          if (
            !actualConstructor.base.addonGroups
              .map((actualAddonGroup) => {
                const sameAddonGroup =
                  sameIdBaseAddonGroupsObject[actualAddonGroup.id];
                return actualAddonGroup.modifiers
                  .map((modifier) => modifier.id)
                  .every((modifier) =>
                    sameAddonGroup.modifiers
                      .map((modifier) => modifier.id)
                      .includes(modifier),
                  );
              })
              .every((hasModifier) => hasModifier)
          )
            return false;
          return true;
        }
      }
    });

    return {
      dishes: actualDishes.map(
        (dish): DishToValidateModel => ({
          productId: dish.product.id,
          quantity: dish.quantity,
          orderItemId: v4(),
          base: dish.base,
        }),
      ),
      constructors: actualConstructors.map(
        (constructor): ConstructorToValidateModel => ({
          productId: constructor.product.id,
          quantity: constructor.quantity,
          orderItemId: v4(),
          base: constructor.base,
        }),
      ),
    };
  },
);

export const repeatOrderFx = createEffect(
  async ({
    order,
    catalogId,
    promoCode,
    promotionProduct,
    type,
    address,
    restaurant,
    user: customer,
    timeTo,
    urgent,
    deliveryZone,
  }: {
    order: Order;
    catalogId: string;
    deliveryZone: DeliveryZone | null;
    promoCode: string | null;
    restaurant: Restaurant | null;
    address: Address.ReceivingAddressV2 | null;
    promotionProduct: PromotionProduct | null;
    user: GetCustomerResponseV1 | null;
    type: OrderType;
    timeTo: string | null;
    urgent: boolean | null;
  }) => {
    const { dishes, constructors } = await getRepeatedOrderProductsFx({
      order,
      catalogId,
    });

    const promotionProductId = promotionProduct
      ? promotionProduct.product.product.id
      : null;

    const validationPayload = {
      deliveryZone,
      request: {
        dishes,
        constructors,
        timeTo,
        urgent,
        cutleries: [],
        promoCode,
        type,
        receivingAddress: address,
        receivingRestaurant: restaurant,
        promotionProduct: promotionProductId,
        customerCount: 1,
        customer: customer
          ? {
              name: customer?.fullName?.firstName || '',
              phone: customer?.phone || '',
            }
          : null,
      },
    };

    await validateFx(validationPayload);

    return { useCustomerCutleries: false };
  },
);

export const validateFx = createEffect<
  ValidateFxModel,
  {
    response: OrdersValidateResponseV4.Response;
    timeIntervals:
      | GetDeliveryZoneTimeIntervalsResponseV2
      | GetRestaurantsTimeIntervalsResponseV2
      | null;
    timeTo: string | null;
    urgent: boolean | null;
    paymentTypes: PaymentType[];
    paymentType: PaymentType | null;
  },
  BaseErrorResponse.ErrorResponse
>(async (payload) => {
  const { timeTo, urgent, type, receivingRestaurant } = payload.request;
  let timeIntervals:
    | GetDeliveryZoneTimeIntervalsResponseV2
    | GetRestaurantsTimeIntervalsResponseV2;
  let newTimeTo: string | null = null;
  let newUrgent: boolean | null = null;
  if (type === 'COURIER' && payload.deliveryZone?.id) {
    timeIntervals = await FeatSDKV2.deliveryZone.getTimeIntervals(
      payload.deliveryZone.id,
    );
  } else {
    timeIntervals = await FeatSDKV2.restaurants.getTimeIntervals(
      receivingRestaurant!.id,
    );
  }

  const schedule = receivingRestaurant
    ? {
        openAt: receivingRestaurant.openAt,
        closeAt: receivingRestaurant.closeAt,
      }
    : {
        openAt: payload.deliveryZone!.openAt,
        closeAt: payload.deliveryZone!.closeAt,
      };
  if (urgent === true) {
    newTimeTo = null;
    newUrgent = true;
  } else {
    if (timeTo) {
      if (timeIntervals.timeVariants.indexOf(timeTo) !== -1) {
        newTimeTo = timeTo;
        newUrgent = null;
      } else {
        if (
          isInSchedule(
            {
              timeInterval: timeIntervals.timeVariants[0],
              schedule,
            },
            true,
          )
        ) {
          newTimeTo = null;
          newUrgent = true;
        } else {
          newTimeTo = timeIntervals.timeVariants[0];
          newUrgent = null;
        }
      }
    } else {
      if (
        isInSchedule(
          {
            timeInterval: timeIntervals.timeVariants[0],
            schedule,
          },
          true,
        )
      ) {
        newTimeTo = null;
        newUrgent = true;
      } else {
        newTimeTo = timeIntervals.timeVariants[0];
        newUrgent = null;
      }
    }
  }
  const response =
    payload.request.customer === null
      ? await FeatSDKV4.orders.notSecureOrdersValidate({
          receiptInformation: {
            ...payload.request,
            receivingRestaurantId: receivingRestaurant?.id || null,
            timeTo: newTimeTo,
            urgent: newUrgent,
          },
        })
      : await FeatSDKV4.orders.ordersValidate({
          receiptInformation: {
            ...payload.request,
            receivingRestaurantId: receivingRestaurant?.id || null,
            timeTo: newTimeTo,
            urgent: newUrgent,
          },
        });

  const paymentTypes =
    payload.request.customer === null
      ? await FeatSDKV3.orders.notSecureOrdersAvaliablePaymentTypes({
          receiptInformation: {
            ...payload.request,
            receivingRestaurantId: receivingRestaurant?.id || null,
            timeTo: newTimeTo,
            urgent: newUrgent,
          },
        })
      : await FeatSDKV3.orders.ordersAvaliablePaymentTypes({
          receiptInformation: {
            ...payload.request,
            receivingRestaurantId: receivingRestaurant?.id || null,
            timeTo: newTimeTo,
            urgent: newUrgent,
          },
        });

  const paymentType = (() => {
    const filteredPaymentTypes = paymentTypes.filter(
      (paymentType) =>
        paymentType !== 'BONUS' &&
        paymentType !== 'SBERPAY' &&
        paymentType !== 'SAVED',
    );

    if (filteredPaymentTypes.length === 1) {
      return filteredPaymentTypes[0];
    }

    return null;
  })();

  return {
    response,
    timeIntervals,
    timeTo: newTimeTo,
    urgent: newUrgent,
    paymentTypes,
    paymentType,
  };
});

export const createOrderFx = createEffect(
  async ({
    dishes,
    constructors,
    cutleries,
    type,
    urgent,
    timeTo,
    receivingAddress,
    receivingRestaurantId,
    isLogin,
    name,
    phone,
    promotionProduct,
    promoCode,
    customerCount,
    paymentType,
    comment,
    changeFrom,
    bonusAmount,
    categories,
    paymentCardId,
  }: CreateOrderFxModel & {
    categories: CatalogCategory[];
    paymentCardId: string | null;
  }) => {
    const validateQuery = {
      dishes,
      constructors,
      cutleries,
      type,
      urgent,
      timeTo,
      receivingAddress,
      receivingRestaurantId,
      promotionProduct,
      promoCode,
      customerCount,
    };

    const currentTransactiobMethod = {
      CASH: FeatSDKV1.transactions.cash,
      TERMINAL: FeatSDKV1.transactions.terminal,
      CARD: FeatSDKV1.transactions.card,
      SAVED: FeatSDKV1.transactions.saved,
      SBP: FeatSDKV1.transactions.sbp,
    };

    const validateResponse = isLogin
      ? await FeatSDKV4.orders.ordersValidate({
          receiptInformation: {
            ...validateQuery,
            customer: {
              name,
              phone,
            },
          },
        })
      : await FeatSDKV4.orders.notSecureOrdersValidate({
          receiptInformation: {
            ...validateQuery,
            customer: {
              name,
              phone,
            },
          },
        });

    const createOrderResponse = await (async () => {
      const { dishes, constructors, cutleries, promotion } = validateResponse;
      const { promoCode, promotionProduct } = promotion;

      const createQuery: OrdersCreateRequestV3.Request = {
        comment,
        changeFrom,
        receiptInformation: {
          type,
          urgent,
          dishes: mapDishesFromCartToValidate(dishes),
          constructors: mapConstructorsFromCartToValidate(constructors),
          cutleries: cutleries.map((cutlery) => ({
            orderItemId: cutlery.orderItemId,
            complectationId: cutlery.complectation.complectation.id,
            quantity: cutlery.quantity,
          })),
          receivingAddress: receivingAddress,
          receivingRestaurantId: receivingRestaurantId,
          promotionProduct: promotionProduct?.product.product.id || null,
          customerCount: 1,
          promoCode: promoCode || null,
          customer: {
            name,
            phone,
          },
          timeTo,
        },
      };

      return isLogin
        ? await FeatSDKV3.orders.ordersCreate(createQuery)
        : await FeatSDKV3.orders.notSecureOrdersCreate(createQuery);
    })();

    const { number, id } = createOrderResponse;

    bonusAmount !== null &&
      (await FeatSDKV1.transactions.bonus({
        orderId: id,
        amount: bonusAmount,
      }));

    let transactionResponse;
    if (paymentType !== 'SAVED') {
      transactionResponse = await currentTransactiobMethod[paymentType]({
        orderId: id,
        amount:
          bonusAmount === null
            ? validateResponse.price.total
            : validateResponse.price.total - bonusAmount,
      });
    } else {
      transactionResponse = await currentTransactiobMethod[paymentType]({
        orderId: id,
        amount:
          bonusAmount === null
            ? validateResponse.price.total
            : validateResponse.price.total - bonusAmount,
        paymentCardId: paymentCardId!,
      });
    }

    const lastOrder: LastOrder = {
      order: { ...createOrderResponse, paid: false },
      changed: false,
    };

    //яндекс метрика
    //@ts-ignore
    window.dataLayer.push({ ecommerce: null });
    const categoriesObj = Object.fromEntries(
      categories.map((category) => [category.id, category]),
    );
    //@ts-ignore
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: id,
        value: createOrderResponse.price.total,
        tax: '0',
        shipping: '0',
        currency: 'RUB',
        coupon: promoCode || '',
        items: [
          ...createOrderResponse.dishes,
          ...createOrderResponse.constructors,
        ].map((product) => ({
          item_name: product.product.name,
          item_id: product.product.id,
          price: product.price.total,
          item_category: categoriesObj[product.product.categoryId].name,
          quantity: product.quantity || 0,
        })),
      },
    });

    return {
      orderId: id,
      orderNumber: number,
      url:
        paymentType === 'CARD' || paymentType === 'SBP'
          ? (transactionResponse as CardTransactionResponseV1).url
          : undefined,
      paymentType,
      lastOrder,
      dishes: createOrderResponse.dishes,
      constructors: createOrderResponse.constructors,
      price: createOrderResponse.price,
    };
  },
);

export const setPromocodeFx = createEffect<
  setPromocodeFxModel,
  {
    response: OrdersValidateResponseV4.Response;
    promoCode: null | string;
    timeIntervals: TimeIntervals | null;
    timeTo: string | null;
    urgent: boolean | null;
    paymentTypes: PaymentType[];
    paymentType: PaymentType | null;
  },
  BaseErrorResponse.ErrorResponse
>(async (payload) => {
  const {
    promoCode,
    validationPayload: {
      deliveryZoneId,
      request: { timeTo, urgent, type, receivingRestaurantId },
    },
  } = payload;
  let timeIntervals: TimeIntervals | null = null;
  let newTimeTo: string | null = null;
  let newUrgent: boolean | null = null;
  if (type === 'COURIER' && deliveryZoneId) {
    timeIntervals = await FeatSDKV2.deliveryZone.getTimeIntervals(
      deliveryZoneId,
    );
  } else {
    if (receivingRestaurantId)
      timeIntervals = await FeatSDKV2.restaurants.getTimeIntervals(
        receivingRestaurantId,
      );
  }
  if (urgent === true) {
    newTimeTo = null;
    newUrgent = true;
  } else {
    if (timeTo) {
      if (timeIntervals?.timeVariants.indexOf(timeTo) !== -1) {
        newTimeTo = timeTo;
        newUrgent = null;
      } else {
        newTimeTo = null;
        newUrgent = true;
      }
    } else {
      newTimeTo = null;
      newUrgent = true;
    }
  }
  const response =
    payload.validationPayload.request.customer === null
      ? await FeatSDKV4.orders.notSecureOrdersValidate({
          receiptInformation: {
            ...payload.validationPayload.request,
            dishes: mapDishesFromCartToValidate(
              payload.validationPayload.request.dishes,
            ),
            constructors: mapConstructorsFromCartToValidate(
              payload.validationPayload.request.constructors,
            ),
            timeTo: newTimeTo,
            urgent: newUrgent,
            promoCode,
          },
        })
      : await FeatSDKV4.orders.ordersValidate({
          receiptInformation: {
            ...payload.validationPayload.request,
            dishes: mapDishesFromCartToValidate(
              payload.validationPayload.request.dishes,
            ),
            constructors: mapConstructorsFromCartToValidate(
              payload.validationPayload.request.constructors,
            ),
            timeTo: newTimeTo,
            urgent: newUrgent,
            promoCode,
          },
        });

  const paymentTypes =
    payload.validationPayload.request.customer === null
      ? await FeatSDKV3.orders.notSecureOrdersAvaliablePaymentTypes({
          receiptInformation: {
            ...payload.validationPayload.request,
            dishes: mapDishesFromCartToValidate(
              payload.validationPayload.request.dishes,
            ),
            constructors: mapConstructorsFromCartToValidate(
              payload.validationPayload.request.constructors,
            ),
            timeTo: newTimeTo,
            urgent: newUrgent,
            promoCode,
          },
        })
      : await FeatSDKV3.orders.ordersAvaliablePaymentTypes({
          receiptInformation: {
            ...payload.validationPayload.request,
            dishes: mapDishesFromCartToValidate(
              payload.validationPayload.request.dishes,
            ),
            constructors: mapConstructorsFromCartToValidate(
              payload.validationPayload.request.constructors,
            ),
            timeTo: newTimeTo,
            urgent: newUrgent,
            promoCode,
          },
        });

  return {
    response,
    promoCode,
    timeIntervals,
    timeTo: newTimeTo,
    urgent: newUrgent,
    paymentTypes,
    paymentType:
      paymentTypes.filter(
        (paymentType) => paymentType !== 'BONUS' && paymentType !== 'SBERPAY',
      ).length === 1
        ? paymentTypes.filter(
            (paymentType) =>
              paymentType !== 'BONUS' && paymentType !== 'SBERPAY',
          )[0]
        : null,
  };
});

export const addDishToCartFx = createEffect<
  AddDishToCartFxModel & { categories: CatalogCategory[] },
  {
    response: OrdersValidateResponseV4.Response;
    timeIntervals: TimeIntervals | null;
    timeTo: string | null;
    urgent: boolean | null;
    paymentTypes: PaymentType[];
    paymentType: PaymentType | null;
  },
  BaseErrorResponse.ErrorResponse
>(async (payload) => {
  const {
    categories,
    dish,
    validationPayload: { deliveryZoneId, request },
  } = payload;

  const {
    validationPayload: {
      request: { dishes, constructors, promotionProduct, customer },
    },
  } = payload;
  const { timeTo, urgent, type, receivingRestaurantId } = request;
  let timeIntervals: TimeIntervals | null = null;
  let newTimeTo: string | null = null;
  let newUrgent: boolean | null = null;
  let validationPayload: OrdersValidateRequestV4.Request;

  if (type === 'COURIER' && deliveryZoneId) {
    timeIntervals = await FeatSDKV2.deliveryZone.getTimeIntervals(
      deliveryZoneId,
    );
  } else {
    if (receivingRestaurantId)
      timeIntervals = await FeatSDKV2.restaurants.getTimeIntervals(
        receivingRestaurantId,
      );
  }
  if (urgent === true) {
    newTimeTo = null;
    newUrgent = true;
  } else {
    if (timeTo) {
      if (timeIntervals?.timeVariants.indexOf(timeTo) !== -1) {
        newTimeTo = timeTo;
        newUrgent = null;
      } else {
        newTimeTo = null;
        newUrgent = true;
      }
    } else {
      newTimeTo = null;
      newUrgent = true;
    }
  }

  const constructorsToValidate: ConstructorToValidateModel[] =
    mapConstructorsFromCartToValidate(constructors);
  const promotionProductId = promotionProduct
    ? promotionProduct.product.product.id
    : null;

  if (dishes.length === 0) {
    const dishesToValidate: DishToValidateModel[] = [
      {
        orderItemId: v4(),
        ...dish,
      },
    ];
    validationPayload = {
      receiptInformation: {
        ...request,
        dishes: dishesToValidate,
        constructors: constructorsToValidate,
        promotionProduct: promotionProductId,
        customerCount: 1,
        customer: customer
          ? {
              name: customer?.fullName?.firstName || '',
              phone: customer?.phone || '',
            }
          : null,
      },
    };
  } else {
    const { hasDish, index } = hasSameDish(dish, dishes);

    if (hasDish) {
      const dishFromCatalog = dish;
      const newDishes = dishes.map((dish, i) => {
        if (i === index) {
          dish.product.quantity =
            dish.product.quantity + dishFromCatalog.quantity;
        }
        return dish;
      });

      validationPayload = {
        receiptInformation: {
          ...request,
          dishes: mapDishesFromCartToValidate(newDishes),
          constructors: constructorsToValidate,
          promotionProduct: promotionProductId,
          customerCount: 1,
          customer: customer
            ? {
                name: customer?.fullName?.firstName || '',
                phone: customer?.phone || '',
              }
            : null,
        },
      };
    } else {
      validationPayload = {
        receiptInformation: {
          ...request,
          dishes: [
            ...mapDishesFromCartToValidate(dishes),
            {
              orderItemId: v4(),
              ...dish,
            },
          ],
          customer: customer
            ? {
                name: customer?.fullName?.firstName || '',
                phone: customer?.phone || '',
              }
            : null,
          constructors: constructorsToValidate,
          promotionProduct: promotionProductId,
          customerCount: 1,
        },
      };
    }
  }
  const response =
    customer === null
      ? await FeatSDKV4.orders.notSecureOrdersValidate(validationPayload)
      : await FeatSDKV4.orders.ordersValidate(validationPayload);
  const paymentTypes =
    customer === null
      ? await FeatSDKV3.orders.notSecureOrdersAvaliablePaymentTypes(
          validationPayload,
        )
      : await FeatSDKV3.orders.ordersAvaliablePaymentTypes(validationPayload);

  // Яндекс метрика
  const categoriesObj = Object.fromEntries(
    categories.map((category) => [category.id, category]),
  );
  //@ts-ignore
  window.dataLayer.push({ ecommerce: null });
  //@ts-ignore
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      items: [
        {
          item_name: dish.name,
          item_id: dish.productId,
          price: dish.price,
          item_category: categoriesObj[dish.categoryId].name,
          quantity: dish.quantity,
        },
      ],
    },
  });

  return {
    response,
    timeIntervals,
    timeTo: newTimeTo,
    urgent: newUrgent,
    paymentTypes,
    paymentType:
      paymentTypes.filter(
        (paymentType) => paymentType !== 'BONUS' && paymentType !== 'SBERPAY',
      ).length === 1
        ? paymentTypes.filter(
            (paymentType) =>
              paymentType !== 'BONUS' && paymentType !== 'SBERPAY',
          )[0]
        : null,
  };
});

export const addConstructorToCartFx = createEffect<
  AddConstructorToCartFxModel & { categories: CatalogCategory[] },
  {
    response: OrdersValidateResponseV4.Response;
    timeIntervals: TimeIntervals | null;
    timeTo: string | null;
    urgent: boolean | null;
    paymentTypes: PaymentType[];
    paymentType: PaymentType | null;
  },
  BaseErrorResponse.ErrorResponse
>(async (payload) => {
  const {
    constructor,
    validationPayload: { deliveryZoneId, request },
    categories,
  } = payload;

  const {
    validationPayload: {
      request: { dishes, constructors, promotionProduct, customer },
    },
  } = payload;
  const { timeTo, urgent, type, receivingRestaurantId } = request;
  let timeIntervals: TimeIntervals | null = null;
  let newTimeTo: string | null = null;
  let newUrgent: boolean | null = null;
  let validationPayload: OrdersValidateRequestV4.Request;

  if (type === 'COURIER' && deliveryZoneId) {
    timeIntervals = await FeatSDKV2.deliveryZone.getTimeIntervals(
      deliveryZoneId,
    );
  } else {
    if (receivingRestaurantId)
      timeIntervals = await FeatSDKV2.restaurants.getTimeIntervals(
        receivingRestaurantId,
      );
  }
  if (urgent === true) {
    newTimeTo = null;
    newUrgent = true;
  } else {
    if (timeTo) {
      if (timeIntervals?.timeVariants.indexOf(timeTo) !== -1) {
        newTimeTo = timeTo;
        newUrgent = null;
      } else {
        newTimeTo = null;
        newUrgent = true;
      }
    } else {
      newTimeTo = null;
      newUrgent = true;
    }
  }

  const dishesToValidate: DishToValidateModel[] =
    mapDishesFromCartToValidate(dishes);
  const promotionProductId = promotionProduct
    ? promotionProduct.product.product.id
    : null;

  if (dishes.length === 0) {
    const constructorsToValidate: ConstructorToValidateModel[] = [
      {
        orderItemId: v4(),
        ...constructor,
      },
    ];
    validationPayload = {
      receiptInformation: {
        ...request,
        dishes: dishesToValidate,
        constructors: constructorsToValidate,
        promotionProduct: promotionProductId,
        customerCount: 1,
        customer: customer
          ? {
              name: customer?.fullName?.firstName || '',
              phone: customer?.phone || '',
            }
          : null,
      },
    };
  } else {
    const { hasConstructor, index } = hasSameConstructor(constructor, dishes);

    if (hasConstructor) {
      const constructorFromCatalog = constructor;
      const newConstructors = constructors.map((constructor, i) => {
        if (i === index) {
          constructor.product.quantity =
            constructor.product.quantity + constructorFromCatalog.quantity;
        }
        return constructor;
      });

      validationPayload = {
        receiptInformation: {
          ...request,
          dishes: dishesToValidate,
          constructors: mapConstructorsFromCartToValidate(newConstructors),
          promotionProduct: promotionProductId,
          customerCount: 1,
          customer: customer
            ? {
                name: customer?.fullName?.firstName || '',
                phone: customer?.phone || '',
              }
            : null,
        },
      };
    } else {
      validationPayload = {
        receiptInformation: {
          ...request,
          dishes: dishesToValidate,
          customer: customer
            ? {
                name: customer?.fullName?.firstName || '',
                phone: customer?.phone || '',
              }
            : null,
          constructors: [
            ...mapConstructorsFromCartToValidate(constructors),
            {
              orderItemId: v4(),
              ...constructor,
            },
          ],
          promotionProduct: promotionProductId,
          customerCount: 1,
        },
      };
    }
  }
  const response =
    customer === null
      ? await FeatSDKV4.orders.notSecureOrdersValidate(validationPayload)
      : await FeatSDKV4.orders.ordersValidate(validationPayload);
  const paymentTypes =
    customer === null
      ? await FeatSDKV3.orders.notSecureOrdersAvaliablePaymentTypes(
          validationPayload,
        )
      : await FeatSDKV3.orders.ordersAvaliablePaymentTypes(validationPayload);

  // Яндекс метрика
  const categoriesObj = Object.fromEntries(
    categories.map((category) => [category.id, category]),
  );
  //@ts-ignore
  window.dataLayer.push({ ecommerce: null });
  //@ts-ignore
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      items: [
        {
          item_name: constructor.name,
          item_id: constructor.productId,
          price: constructor.price,
          item_category: categoriesObj[constructor.categoryId].name,
          quantity: constructor.quantity,
        },
      ],
    },
  });

  return {
    response,
    timeIntervals,
    timeTo: newTimeTo,
    urgent: newUrgent,
    paymentTypes,
    paymentType:
      paymentTypes.filter(
        (paymentType) => paymentType !== 'BONUS' && paymentType !== 'SBERPAY',
      ).length === 1
        ? paymentTypes.filter(
            (paymentType) =>
              paymentType !== 'BONUS' && paymentType !== 'SBERPAY',
          )[0]
        : null,
  };
});

// export const getOrderLongPollFx = createEffect(
//   FeatSDKV3.orders.getOrderLongPoll,
// );
