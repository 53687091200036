import { $catalogCategories } from 'domains/catalog';
import { reset, spread } from 'patronum';
import {
  ValidateFxModel,
  OrderingForm,
  ProblemsToShowModel,
  ValidationError,
} from './entities';
import { sample } from 'effector';
import {
  createOrderFx,
  validateFx,
  repeatOrderFx,
  setPromocodeFx,
  addDishToCartFx,
  addConstructorToCartFx,
  // getOrderLongPollFx,
} from './effects';
import {
  validate,
  changeConstructorQuantity,
  changeCutleryQuantity,
  changeDishQuantity,
  deleteConstructor,
  deleteCutlery,
  deleteDish,
  changeDishQuantityFromCatalog,
  setTime,
  showCreatedOrderModal,
  closeCreatedOrderModal,
  clearCartAfterOrder,
  changePromotionProduct,
  hideValidationError,
  clearCart,
} from './events';
import {
  $constructors,
  $cutleries,
  $dishes,
  $promoCode,
  $promotionProduct,
  $timeTo,
  $urgent,
  $price,
  $problems,
  $warning,
  $promotion,
  cartGate,
  orderingForm,
  $createdOrderNotificationStore,
  $lastOrder,
  $useCustomerCutleries,
  $validationErrors,
  $hasCriticalCartError,
  $longDeliveryWarning,
  $avaliableOrderPaymentTypes,
} from './stores';
import {
  $type,
  $address,
  $restaurant,
  submitAddress,
  submitRestaurant,
  $deliveryZone,
  $timeIntervals,
  $currency,
} from 'domains/cartography';
import { $user, subscribeLastActiveOrderFx } from 'domains/profile';
import {
  filterBlockingProblems,
  getButtonText,
  getProblemActionType,
  mapConstructorsFromCartToValidate,
  mapDishesFromCartToValidate,
} from './model';
import { FELocalStorageUtil } from 'utils';
import { initCatalogFx } from 'domains/application';
import { t } from 'i18next';

sample({
  clock: initCatalogFx.doneData,
  filter: ({ cartData }) => !!cartData,
  target: validate,
});

sample({
  clock: [changeCutleryQuantity, deleteCutlery],
  fn: () => true,
  target: $useCustomerCutleries,
});

spread({
  //@ts-expect-error
  source: sample({
    clock: addDishToCartFx.doneData,
    fn: ({
      response,
      timeIntervals,
      timeTo,
      urgent,
      paymentType,
      paymentTypes,
    }) => {
      FELocalStorageUtil.setCartData({
        dishes: response.dishes,
        constructors: response.constructors,
      });

      return {
        dishes: response.dishes,
        price: response.price,
        cutleries: response.cutleries,
        constructors: response.constructors,
        problems: response.problems,
        promotionProduct: response.promotion.promotionProduct || null,
        promotion: response.promotion,
        timeIntervals,
        timeTo,
        urgent,
        paymentType,
        paymentTypes,
      };
    },
  }),
  targets: {
    dishes: $dishes,
    price: $price,
    cutleries: $cutleries,
    constructors: $constructors,
    problems: $problems,
    promotionProduct: $promotionProduct,
    promotion: $promotion,
    timeIntervals: $timeIntervals,
    timeTo: $timeTo,
    urgent: $urgent,
    paymentTypes: $avaliableOrderPaymentTypes,
    paymentType: orderingForm.fields.paymentType.$value,
  },
});

spread({
  //@ts-expect-error
  source: sample({
    clock: addConstructorToCartFx.doneData,
    fn: ({
      response,
      timeIntervals,
      timeTo,
      urgent,
      paymentType,
      paymentTypes,
    }) => {
      FELocalStorageUtil.setCartData({
        dishes: response.dishes,
        constructors: response.constructors,
      });

      return {
        dishes: response.dishes,
        price: response.price,
        cutleries: response.cutleries,
        constructors: response.constructors,
        problems: response.problems,
        promotionProduct: response.promotion.promotionProduct || null,
        promotion: response.promotion,
        timeIntervals,
        timeTo,
        urgent,
        paymentType,
        paymentTypes,
      };
    },
  }),
  targets: {
    dishes: $dishes,
    price: $price,
    cutleries: $cutleries,
    constructors: $constructors,
    problems: $problems,
    promotionProduct: $promotionProduct,
    promotion: $promotion,
    timeIntervals: $timeIntervals,
    timeTo: $timeTo,
    urgent: $urgent,
    paymentTypes: $avaliableOrderPaymentTypes,
    paymentType: orderingForm.fields.paymentType.$value,
  },
});

spread({
  //@ts-expect-error
  source: sample({
    clock: validateFx.doneData,
    fn: (payload) => {
      const {
        timeIntervals,
        timeTo,
        urgent,
        response,
        paymentType,
        paymentTypes,
      } = payload;
      FELocalStorageUtil.setCartData({
        dishes: response.dishes,
        constructors: response.constructors,
      });
      return {
        dishes: response.dishes,
        price: response.price,
        cutleries: response.cutleries,
        constructors: response.constructors,
        problems: response.problems,
        promotionProduct: response.promotion.promotionProduct || null,
        promotion: response.promotion,
        timeIntervals,
        timeTo,
        urgent,
        paymentTypes,
        paymentType: {
          paymentType,
        },
      };
    },
  }),
  targets: {
    dishes: $dishes,
    price: $price,
    cutleries: $cutleries,
    constructors: $constructors,
    problems: $problems,
    promotionProduct: $promotionProduct,
    promotion: $promotion,
    timeIntervals: $timeIntervals,
    timeTo: $timeTo,
    urgent: $urgent,
    paymentTypes: $avaliableOrderPaymentTypes,
    paymentType: orderingForm.fields.paymentType.$value,
  },
});

sample({
  clock: validate,
  source: {
    $constructors,
    $cutleries,
    $dishes,
    $promoCode,
    $promotionProduct,
    $type,
    $address,
    $restaurant,
    $user,
    $timeTo,
    $urgent,
    $deliveryZone,
    $useCustomerCutleries,
  },
  fn: ({
    $constructors: constructors,
    $cutleries: cutleries,
    $dishes: dishes,
    $promoCode: promoCode,
    $promotionProduct: promotionProduct,
    $type: type,
    $address: address,
    $restaurant: restaurant,
    $user: customer,
    $timeTo: timeTo,
    $urgent: urgent,
    $deliveryZone: deliveryZone,
    $useCustomerCutleries: useCustomerCutleries,
  }): ValidateFxModel => {
    const promotionProductId = promotionProduct
      ? promotionProduct.product.product.id
      : null;
    return {
      deliveryZone,
      request: {
        dishes: mapDishesFromCartToValidate(dishes),
        constructors: mapConstructorsFromCartToValidate(constructors),
        timeTo,
        urgent,
        cutleries: useCustomerCutleries
          ? cutleries.map((cutlery) => ({
              orderItemId: cutlery.orderItemId,
              complectationId: cutlery.complectation.complectation.id,
              quantity: cutlery.quantity,
            }))
          : [],
        promoCode,
        type,
        receivingAddress: address,
        receivingRestaurant: restaurant,
        promotionProduct: promotionProductId,
        customerCount: 1,
        customer: customer
          ? {
              name: customer?.fullName?.firstName || '',
              phone: customer?.phone || '',
            }
          : null,
      },
    };
  },
  target: validateFx,
});

sample({
  clock: setPromocodeFx,
  fn: ({ promoCode }) => promoCode,
  target: $promoCode,
});
spread({
  //@ts-expect-error
  source: sample({
    clock: setPromocodeFx.doneData,
    fn: ({
      promoCode,
      response,
      timeIntervals,
      timeTo,
      urgent,
      paymentType,
      paymentTypes,
    }) => {
      FELocalStorageUtil.setCartData({
        dishes: response.dishes,
        constructors: response.constructors,
      });

      return {
        dishes: response.dishes,
        price: response.price,
        cutleries: response.cutleries,
        constructors: response.constructors,
        problems: response.problems,
        promotionProduct: response.promotion.promotionProduct || null,
        promotion: response.promotion,
        timeIntervals,
        timeTo,
        urgent,
        promoCode,
        paymentType,
        paymentTypes,
      };
    },
  }),
  targets: {
    promoCode: $promoCode,
    dishes: $dishes,
    price: $price,
    cutleries: $cutleries,
    constructors: $constructors,
    problems: $problems,
    promotionProduct: $promotionProduct,
    promotion: $promotion,
    timeIntervals: $timeIntervals,
    timeTo: $timeTo,
    urgent: $urgent,
    paymentTypes: $avaliableOrderPaymentTypes,
    paymentType: orderingForm.fields.paymentType.$value,
  },
});

spread({
  source: sample({
    clock: setTime,
    source: {
      $constructors,
      $cutleries,
      $dishes,
      $promotionProduct,
      $type,
      $address,
      $restaurant,
      $user,
      $promoCode,
      $deliveryZone,
    },
    fn: (
      {
        $constructors: constructors,
        $cutleries: cutleries,
        $dishes: dishes,
        $promotionProduct: promotionProduct,
        $type: type,
        $address: address,
        $restaurant: restaurant,
        $user: customer,
        $promoCode: promoCode,
        $deliveryZone: deliveryZone,
      },
      { timeTo, urgent },
    ) => {
      const promotionProductId = promotionProduct
        ? promotionProduct.product.product.id
        : null;

      return {
        validate: {
          request: {
            dishes: mapDishesFromCartToValidate(dishes),
            constructors: mapConstructorsFromCartToValidate(constructors),
            timeTo,
            urgent,
            cutleries: cutleries.map((cutlery) => ({
              orderItemId: cutlery.orderItemId,
              complectationId: cutlery.complectation.complectation.id,
              quantity: cutlery.quantity,
            })),
            promoCode,
            type,
            receivingAddress: address,
            receivingRestaurant: restaurant,
            promotionProduct: promotionProductId,
            customerCount: 1,
            customer: customer
              ? {
                  name: customer?.fullName?.firstName || '',
                  phone: customer?.phone || '',
                }
              : null,
          },
          deliveryZone,
        },
        timeTo,
        urgent,
      };
    },
  }),
  targets: {
    timeTo: $timeTo,
    urgent: $urgent,
    validate: validateFx,
  },
});

spread({
  source: sample({
    clock: changeDishQuantity,
    source: {
      $constructors,
      $cutleries,
      $dishes,
      $promoCode,
      $promotionProduct,
      $type,
      $address,
      $restaurant,
      $user,
      $timeTo,
      $urgent,
      $deliveryZone,
      $useCustomerCutleries,
    },
    fn: (
      {
        $constructors: constructors,
        $cutleries: cutleries,
        $dishes: dishes,
        $promoCode: promoCode,
        $promotionProduct: promotionProduct,
        $type: type,
        $address: address,
        $restaurant: restaurant,
        $user: customer,
        $timeTo: timeTo,
        $urgent: urgent,
        $deliveryZone: deliveryZone,
        $useCustomerCutleries: useCustomerCutleries,
      },
      { index, quantity },
    ) => {
      const promotionProductId = promotionProduct
        ? promotionProduct.product.product.id
        : null;
      const newDishes = [...dishes];
      newDishes[index].product.quantity = quantity;
      return {
        dishes: newDishes,
        validate: {
          deliveryZone,
          request: {
            dishes: mapDishesFromCartToValidate(newDishes),
            constructors: mapConstructorsFromCartToValidate(constructors),
            timeTo,
            urgent,
            cutleries: useCustomerCutleries
              ? cutleries.map((cutlery) => ({
                  orderItemId: cutlery.orderItemId,
                  complectationId: cutlery.complectation.complectation.id,
                  quantity: cutlery.quantity,
                }))
              : [],
            promoCode,
            type,
            receivingAddress: address,
            receivingRestaurant: restaurant,
            promotionProduct: promotionProductId,
            customerCount: 1,
            customer: customer
              ? {
                  name: customer?.fullName?.firstName || '',
                  phone: customer?.phone || '',
                }
              : null,
          },
        },
      };
    },
  }),
  targets: {
    dishes: $dishes,
    validate: validateFx,
  },
});
spread({
  source: sample({
    clock: changeConstructorQuantity,
    source: {
      $constructors,
      $cutleries,
      $dishes,
      $promoCode,
      $promotionProduct,
      $type,
      $address,
      $restaurant,
      $user,
      $timeTo,
      $urgent,
      $deliveryZone,
      $useCustomerCutleries,
    },
    fn: (
      {
        $constructors: constructors,
        $cutleries: cutleries,
        $dishes: dishes,
        $promoCode: promoCode,
        $promotionProduct: promotionProduct,
        $type: type,
        $address: address,
        $restaurant: restaurant,
        $user: customer,
        $timeTo: timeTo,
        $urgent: urgent,
        $deliveryZone: deliveryZone,
        $useCustomerCutleries: useCustomerCutleries,
      },
      { index, quantity },
    ) => {
      const promotionProductId = promotionProduct
        ? promotionProduct.product.product.id
        : null;
      const newConstructors = [...constructors];
      newConstructors[index].product.quantity = quantity;
      return {
        constructors: newConstructors,
        validate: {
          deliveryZone,
          request: {
            dishes: mapDishesFromCartToValidate(dishes),
            constructors: mapConstructorsFromCartToValidate(newConstructors),
            timeTo,
            urgent,
            cutleries: useCustomerCutleries
              ? cutleries.map((cutlery) => ({
                  orderItemId: cutlery.orderItemId,
                  complectationId: cutlery.complectation.complectation.id,
                  quantity: cutlery.quantity,
                }))
              : [],
            promoCode,
            type,
            receivingAddress: address,
            receivingRestaurant: restaurant,
            promotionProduct: promotionProductId,
            customerCount: 1,
            customer: customer
              ? {
                  name: customer?.fullName?.firstName || '',
                  phone: customer?.phone || '',
                }
              : null,
          },
        },
      };
    },
  }),
  targets: {
    constructors: $constructors,
    validate: validateFx,
  },
});
spread({
  source: sample({
    clock: changeCutleryQuantity,
    source: {
      $constructors,
      $cutleries,
      $dishes,
      $promoCode,
      $promotionProduct,
      $type,
      $address,
      $restaurant,
      $user,
      $timeTo,
      $urgent,
      $deliveryZone,
      $useCustomerCutleries,
    },
    fn: (
      {
        $constructors: constructors,
        $cutleries: cutleries,
        $dishes: dishes,
        $promoCode: promoCode,
        $promotionProduct: promotionProduct,
        $type: type,
        $address: address,
        $restaurant: restaurant,
        $user: customer,
        $timeTo: timeTo,
        $urgent: urgent,
        $deliveryZone: deliveryZone,
        $useCustomerCutleries: useCustomerCutleries,
      },
      { index, quantity },
    ) => {
      const promotionProductId = promotionProduct
        ? promotionProduct.product.product.id
        : null;
      const newCutleries = [...cutleries];
      newCutleries[index].quantity = quantity;
      return {
        cutleries: newCutleries,
        validate: {
          deliveryZone,
          request: {
            dishes: mapDishesFromCartToValidate(dishes),
            constructors: mapConstructorsFromCartToValidate(constructors),
            timeTo,
            urgent,
            cutleries: useCustomerCutleries
              ? newCutleries.map((cutlery) => ({
                  orderItemId: cutlery.orderItemId,
                  complectationId: cutlery.complectation.complectation.id,
                  quantity: cutlery.quantity,
                }))
              : [],
            promoCode,
            type,
            receivingAddress: address,
            receivingRestaurant: restaurant,
            promotionProduct: promotionProductId,
            customerCount: 1,
            customer: customer
              ? {
                  name: customer?.fullName?.firstName || '',
                  phone: customer?.phone || '',
                }
              : null,
          },
        },
      };
    },
  }),
  targets: {
    cutleries: $cutleries,
    validate: validateFx,
  },
});

spread({
  source: sample({
    clock: deleteDish,
    source: {
      $constructors,
      $cutleries,
      $dishes,
      $promoCode,
      $promotionProduct,
      $type,
      $address,
      $restaurant,
      $user,
      $timeTo,
      $urgent,
      $deliveryZone,
      $useCustomerCutleries,
    },
    filter: ({ $constructors: constructors, $dishes: dishes }, { index }) => {
      const newDishes = [...dishes];
      newDishes.splice(index, 1);

      return Boolean(constructors.length !== 0 || newDishes.length !== 0);
    },
    fn: (
      {
        $constructors: constructors,
        $cutleries: cutleries,
        $dishes: dishes,
        $promoCode: promoCode,
        $promotionProduct: promotionProduct,
        $type: type,
        $address: address,
        $restaurant: restaurant,
        $user: customer,
        $timeTo: timeTo,
        $urgent: urgent,
        $deliveryZone: deliveryZone,
        $useCustomerCutleries: useCustomerCutleries,
      },
      { index },
    ) => {
      const promotionProductId = promotionProduct
        ? promotionProduct.product.product.id
        : null;
      const newDishes = [...dishes];
      newDishes.splice(index, 1);
      return {
        dishes: newDishes,
        validate: {
          deliveryZone,
          request: {
            dishes: mapDishesFromCartToValidate(newDishes),
            constructors: mapConstructorsFromCartToValidate(constructors),
            timeTo,
            urgent,
            cutleries: useCustomerCutleries
              ? cutleries.map((cutlery) => ({
                  orderItemId: cutlery.orderItemId,
                  complectationId: cutlery.complectation.complectation.id,
                  quantity: cutlery.quantity,
                }))
              : [],
            promoCode,
            type,
            receivingAddress: address,
            receivingRestaurant: restaurant,
            promotionProduct: promotionProductId,
            customerCount: 1,
            customer: customer
              ? {
                  name: customer?.fullName?.firstName || '',
                  phone: customer?.phone || '',
                }
              : null,
          },
        },
      };
    },
  }),
  targets: {
    dishes: $dishes,
    validate: validateFx,
  },
});
sample({
  clock: deleteDish,
  source: {
    $constructors,
    $dishes,
  },
  filter: ({ $constructors: constructors, $dishes: dishes }, { index }) => {
    const newDishes = [...dishes];
    newDishes.splice(index, 1);

    return !Boolean(constructors.length !== 0 || newDishes.length !== 0);
  },
  target: clearCartAfterOrder,
});

spread({
  source: sample({
    clock: deleteConstructor,
    source: {
      $constructors,
      $cutleries,
      $dishes,
      $promoCode,
      $promotionProduct,
      $type,
      $address,
      $restaurant,
      $user,
      $timeTo,
      $urgent,
      $deliveryZone,
      $useCustomerCutleries,
    },
    filter: ({ $constructors: constructors, $dishes: dishes }, { index }) => {
      const newConstructors = [...constructors];
      newConstructors.splice(index, 1);

      return Boolean(newConstructors.length !== 0 || dishes.length !== 0);
    },
    fn: (
      {
        $constructors: constructors,
        $cutleries: cutleries,
        $dishes: dishes,
        $promoCode: promoCode,
        $promotionProduct: promotionProduct,
        $type: type,
        $address: address,
        $restaurant: restaurant,
        $user: customer,
        $timeTo: timeTo,
        $urgent: urgent,
        $deliveryZone: deliveryZone,
        $useCustomerCutleries: useCustomerCutleries,
      },
      { index },
    ) => {
      const promotionProductId = promotionProduct
        ? promotionProduct.product.product.id
        : null;
      const newConstructors = [...constructors];
      newConstructors.splice(index, 1);
      return {
        constructors: newConstructors,
        validate: {
          deliveryZone,
          request: {
            dishes: mapDishesFromCartToValidate(dishes),
            constructors: mapConstructorsFromCartToValidate(newConstructors),
            timeTo,
            urgent,
            cutleries: useCustomerCutleries
              ? cutleries.map((cutlery) => ({
                  orderItemId: cutlery.orderItemId,
                  complectationId: cutlery.complectation.complectation.id,
                  quantity: cutlery.quantity,
                }))
              : [],
            promoCode,
            type,
            receivingAddress: address,
            receivingRestaurant: restaurant,
            promotionProduct: promotionProductId,
            customerCount: 1,
            customer: customer
              ? {
                  name: customer?.fullName?.firstName || '',
                  phone: customer?.phone || '',
                }
              : null,
          },
        },
      };
    },
  }),
  targets: {
    constructors: $constructors,
    validate: validateFx,
  },
});
sample({
  clock: deleteConstructor,
  source: {
    $constructors,
    $dishes,
  },
  filter: ({ $constructors: constructors, $dishes: dishes }, { index }) => {
    const newConstructors = [...constructors];
    newConstructors.splice(index, 1);

    return !Boolean(dishes.length !== 0 || newConstructors.length !== 0);
  },
  target: clearCartAfterOrder,
});
spread({
  source: sample({
    clock: deleteCutlery,
    source: {
      $constructors,
      $cutleries,
      $dishes,
      $promoCode,
      $promotionProduct,
      $type,
      $address,
      $restaurant,
      $user,
      $timeTo,
      $urgent,
      $deliveryZone,
      $useCustomerCutleries,
    },
    fn: (
      {
        $constructors: constructors,
        $cutleries: cutleries,
        $dishes: dishes,
        $promoCode: promoCode,
        $promotionProduct: promotionProduct,
        $type: type,
        $address: address,
        $restaurant: restaurant,
        $user: customer,
        $timeTo: timeTo,
        $urgent: urgent,
        $deliveryZone: deliveryZone,
        $useCustomerCutleries: useCustomerCutleries,
      },
      { index },
    ) => {
      const promotionProductId = promotionProduct
        ? promotionProduct.product.product.id
        : null;
      const newCutleries = [...cutleries];
      newCutleries.splice(index, 1);
      return {
        cutleries: newCutleries,
        validate: {
          deliveryZone,
          request: {
            dishes: mapDishesFromCartToValidate(dishes),
            constructors: mapConstructorsFromCartToValidate(constructors),
            timeTo,
            urgent,
            cutleries: useCustomerCutleries
              ? newCutleries.map((cutlery) => ({
                  orderItemId: cutlery.orderItemId,
                  complectationId: cutlery.complectation.complectation.id,
                  quantity: cutlery.quantity,
                }))
              : [],
            promoCode,
            type,
            receivingAddress: address,
            receivingRestaurant: restaurant,
            promotionProduct: promotionProductId,
            customerCount: 1,
            customer: customer
              ? {
                  name: customer?.fullName?.firstName || '',
                  phone: customer?.phone || '',
                }
              : null,
          },
        },
      };
    },
  }),
  targets: {
    cutleries: $cutleries,
    validate: validateFx,
  },
});

sample({
  clock: cartGate.open,
  source: { $user },
  filter: ({ $user: user }) => user !== null,
  fn: ({ $user: user }): Partial<OrderingForm> => {
    return {
      name: user?.fullName?.firstName || null,
      phone: user?.phone || null,
    };
  },
  target: orderingForm.set,
});
sample({
  clock: cartGate.close,
  target: orderingForm.reset,
});

sample({
  clock: cartGate.open,
  source: { $dishes, $constructors },
  filter: ({ $dishes: dishes, $constructors: constructors }) =>
    !!dishes.length || !!constructors.length,
  target: validate,
});

sample({
  clock: [submitAddress, submitRestaurant],
  source: {
    $dishes,
    $constructors,
  },
  filter: ({ $dishes: dishes, $constructors: constructors }) =>
    dishes.length !== 0 || constructors.length !== 0,
  target: validate,
});

sample({
  clock: changeDishQuantityFromCatalog,
  source: {
    $constructors,
    $dishes,
  },
  filter: (
    { $constructors: constructors, $dishes: dishes },
    { productId, quantity },
  ) => {
    const newDishes = [...dishes];
    const currentDishes = newDishes.filter(
      (dish) => dish.product.product.id === productId,
    );
    const currentDishesQuantity = currentDishes.reduce(
      (prev, dish) => prev + dish.product.quantity,
      0,
    );
    const lastIndex =
      newDishes.length -
      1 -
      newDishes
        .reverse()
        .findIndex((dish) => dish.product.product.id === productId);
    newDishes.reverse();
    if (currentDishesQuantity < quantity) {
      newDishes[lastIndex].product.quantity++;
    }
    if (currentDishesQuantity > quantity) {
      const lastIndexProductQuantity = newDishes[lastIndex].product.quantity;
      if (lastIndexProductQuantity > 1) {
        newDishes[lastIndex].product.quantity--;
      } else {
        newDishes.splice(lastIndex, 1);
      }
    }

    return !Boolean(constructors.length !== 0 || newDishes.length !== 0);
  },
  target: clearCartAfterOrder,
});

spread({
  source: sample({
    clock: changeDishQuantityFromCatalog,
    source: {
      $catalogCategories,
      $constructors,
      $cutleries,
      $dishes,
      $promoCode,
      $promotionProduct,
      $type,
      $address,
      $restaurant,
      $user,
      $timeTo,
      $urgent,
      $deliveryZone,
      $useCustomerCutleries,
    },
    filter: (
      {
        $constructors: constructors,
        $dishes: dishes,
        $catalogCategories: categories,
      },
      { productId, quantity },
    ) => {
      const newDishes = [...dishes];
      const currentDishes = newDishes.filter(
        (dish) => dish.product.product.id === productId,
      );
      const currentDishesQuantity = currentDishes.reduce(
        (prev, dish) => prev + dish.product.quantity,
        0,
      );
      const lastIndex =
        newDishes.length -
        1 -
        newDishes
          .reverse()
          .findIndex((dish) => dish.product.product.id === productId);
      newDishes.reverse();
      if (currentDishesQuantity < quantity) {
        newDishes[lastIndex].product.quantity++;
      }
      if (currentDishesQuantity > quantity) {
        const lastIndexProductQuantity = newDishes[lastIndex].product.quantity;
        if (lastIndexProductQuantity > 1) {
          newDishes[lastIndex].product.quantity--;
        } else {
          // Яндекс метрика
          const targetRemoveDish = newDishes[lastIndex];
          const categoriesObj = Object.fromEntries(
            categories.map((category) => [category.id, category]),
          );
          //@ts-ignore
          window.dataLayer.push({ ecommerce: null });
          //@ts-ignore
          window.dataLayer.push({
            event: 'remove_from_cart',
            ecommerce: {
              items: [
                {
                  item_name: targetRemoveDish.product.product.name,
                  item_id: targetRemoveDish.product.product.id,
                  price: targetRemoveDish.product.price.total ?? 0,
                  item_category:
                    categoriesObj[targetRemoveDish.product.product.categoryId],
                  quantity: targetRemoveDish.product.quantity,
                },
              ],
            },
          });
          newDishes.splice(lastIndex, 1);
        }
      }

      return Boolean(constructors.length !== 0 || newDishes.length !== 0);
    },
    fn: (
      {
        $constructors: constructors,
        $cutleries: cutleries,
        $dishes: dishes,
        $promoCode: promoCode,
        $promotionProduct: promotionProduct,
        $type: type,
        $address: address,
        $restaurant: restaurant,
        $user: customer,
        $timeTo: timeTo,
        $urgent: urgent,
        $deliveryZone: deliveryZone,
        $useCustomerCutleries: useCustomerCutleries,
      },
      { productId, quantity },
    ) => {
      const promotionProductId = promotionProduct
        ? promotionProduct.product.product.id
        : null;
      const newDishes = [...dishes];
      const currentDishes = newDishes.filter(
        (dish) => dish.product.product.id === productId,
      );
      const currentDishesQuantity = currentDishes.reduce(
        (prev, dish) => prev + dish.product.quantity,
        0,
      );
      const lastIndex =
        newDishes.length -
        1 -
        newDishes
          .reverse()
          .findIndex((dish) => dish.product.product.id === productId);
      newDishes.reverse();
      if (currentDishesQuantity < quantity) {
        newDishes[lastIndex].product.quantity++;
      }
      if (currentDishesQuantity > quantity) {
        const lastIndexProductQuantity = newDishes[lastIndex].product.quantity;
        if (lastIndexProductQuantity > 1) {
          newDishes[lastIndex].product.quantity--;
        } else {
          newDishes.splice(lastIndex, 1);
        }
      }
      return {
        dishes: newDishes,
        validate: {
          deliveryZone,
          request: {
            dishes: mapDishesFromCartToValidate(newDishes),
            constructors: mapConstructorsFromCartToValidate(constructors),
            timeTo,
            urgent,
            cutleries: useCustomerCutleries
              ? cutleries.map((cutlery) => ({
                  orderItemId: cutlery.orderItemId,
                  complectationId: cutlery.complectation.complectation.id,
                  quantity: cutlery.quantity,
                }))
              : [],
            promoCode,
            type,
            receivingAddress: address,
            receivingRestaurant: restaurant,
            promotionProduct: promotionProductId,
            customerCount: 1,
            customer: customer
              ? {
                  name: customer?.fullName?.firstName || '',
                  phone: customer?.phone || '',
                }
              : null,
          },
        },
      };
    },
  }),
  targets: {
    validate: validateFx,
    dishes: $dishes,
  },
});

spread({
  //@ts-expect-error
  source: sample({
    clock: createOrderFx.done,
    source: $currency,
    fn: (currency, { result, params }) => {
      FELocalStorageUtil.setLastOrderId({ lastOrderId: result.orderId });

      const dishes = result.dishes;
      const constructors = result.constructors;

      return {
        showCreatedOrderModal: {
          icon: 'checkmark',
          orderId: result.orderId,
          title: t('cart.orderResult.SUCCESS.title'),
          description: t('cart.orderResult.SUCCESS.description', {
            orderNumber: result.orderNumber,
          }),
          button: {
            text: t('cart.orderResult.SUCCESS.buttonText'),
            onClick: () => {
              clearCartAfterOrder();
            },
          },
          type: 'SUCCESS',
          orderNumber: result.orderNumber,
          price: `${result.price.total}`,
          items: [
            ...dishes.map((dish) => ({
              Product: {
                productID: dish.product.id,
                category: '1',
                price: `${dish.price.total}`,
                priceCurrency: currency === '₽' ? 'RUB' : 'AED',
              },
              orderQuantity: dish.quantity,
              additionalType: 'sale',
            })),
            ...constructors.map((constructor) => ({
              Product: {
                productID: constructor.product.id,
                category: '1',
                price: `${constructor.price.total}`,
                priceCurrency: currency === '₽' ? 'RUB' : 'AED',
              },
              orderQuantity: constructor.quantity,
              additionalType: 'sale',
            })),
          ],
        },
        lastOrder: result.lastOrder,
        // getOrderLongPoll: {
        //   id: result.orderId,
        //   timeout: 25
        // },
        getOrderLongPoll: {
          orderId: result.orderId,
        },
      };
    },
  }),
  targets: {
    showCreatedOrderModal: showCreatedOrderModal,
    lastOrder: $lastOrder,
    // getOrderLongPoll: getOrderLongPollFx,
    getOrderLongPoll: subscribeLastActiveOrderFx,
  },
});

sample({
  clock: createOrderFx.failData,
  fn: (): {
    icon: 'checkmark' | 'error';
    title: string;
    description: string;
    button: {
      text: string;
      onClick: () => void;
    };
    orderId: string | null;
    type: 'ERROR' | 'SUCCESS';
    orderNumber: null;
    price: null;
    items: null;
  } => ({
    icon: 'error',
    title: t('cart.orderResult.ERROR.title'),
    description: t('cart.orderResult.ERROR.description'),
    orderId: null,
    type: 'ERROR',
    button: {
      text: t('cart.orderResult.ERROR.buttonText'),
      onClick: () => {},
    },
    items: null,
    orderNumber: null,
    price: null,
  }),
  target: showCreatedOrderModal,
});

$createdOrderNotificationStore.reset(closeCreatedOrderModal);

sample({
  clock: showCreatedOrderModal,
  fn: (payload) => ({
    show: true,
    ...payload,
  }),
  target: $createdOrderNotificationStore,
});

spread({
  //@ts-expect-error
  source: sample({
    clock: clearCartAfterOrder,
    fn: () => {
      FELocalStorageUtil.clearCartData();
      return {
        dishes: [],
        constructors: [],
        cutleries: [],
        promoCode: null,
        promotion: null,
        price: null,
        warning: null,
        longDeliveryWarning: null,
        pronlems: [],
        timeTo: null,
        urgent: true,
        promotionProduct: null,
        criticalError: false,
      };
    },
  }),
  targets: {
    dishes: $dishes,
    constructors: $constructors,
    cutleries: $cutleries,
    promoCode: $promoCode,
    promotion: $promotion,
    price: $price,
    warning: $warning,
    longDeliveryWarning: $longDeliveryWarning,
    pronlems: $problems,
    timeTo: $timeTo,
    urgent: $urgent,
    promotionProduct: $promotionProduct,
    criticalError: $hasCriticalCartError,
  },
});

spread({
  source: sample({
    clock: [
      validateFx.doneData,
      setPromocodeFx.doneData,
      addDishToCartFx.doneData,
      addConstructorToCartFx.doneData,
    ],
    // filter: ({ response: { problems } }) =>
    //   problems.some((problem) => problem.level === 'WARNING'),
    fn: ({ response: { problems } }) => {
      const warningProblems = problems.filter(
        (problem) => problem.level === 'WARNING',
      );
      const warnings = warningProblems.filter(
        (problem) => problem.reason !== 'DELIVERY_CAN_BE_LONGER',
      );
      const longDeliveryWarning = warningProblems.find(
        (problem) => problem.reason === 'DELIVERY_CAN_BE_LONGER',
      );

      return {
        warning: warnings.length ? warnings[0].message : null,
        longDeliveryWarning: longDeliveryWarning
          ? longDeliveryWarning.message
          : null,
      };
    },
  }),
  targets: {
    warning: $warning,
    longDeliveryWarning: $longDeliveryWarning,
  },
});

sample({
  clock: [
    validateFx.failData,
    setPromocodeFx.failData,
    addDishToCartFx.failData,
    addConstructorToCartFx.failData,
  ],
  fn: () => null,
  target: [$warning, $longDeliveryWarning],
});

spread({
  source: sample({
    clock: changePromotionProduct,
    source: {
      $constructors,
      $cutleries,
      $dishes,
      $promoCode,
      $type,
      $address,
      $restaurant,
      $user,
      $timeTo,
      $urgent,
      $deliveryZone,
      $useCustomerCutleries,
    },
    fn: (
      {
        $constructors: constructors,
        $cutleries: cutleries,
        $dishes: dishes,
        $promoCode: promoCode,
        $type: type,
        $address: address,
        $restaurant: restaurant,
        $user: customer,
        $timeTo: timeTo,
        $urgent: urgent,
        $deliveryZone: deliveryZone,
        $useCustomerCutleries: useCustomerCutleries,
      },
      { promotionProduct },
    ) => {
      const promotionProductId = promotionProduct
        ? promotionProduct.product.product.id
        : null;
      return {
        promotionProduct,
        validate: {
          deliveryZone,
          request: {
            dishes: mapDishesFromCartToValidate(dishes),
            constructors: mapConstructorsFromCartToValidate(constructors),
            timeTo,
            urgent,
            cutleries: useCustomerCutleries
              ? cutleries.map((cutlery) => ({
                  orderItemId: cutlery.orderItemId,
                  complectationId: cutlery.complectation.complectation.id,
                  quantity: cutlery.quantity,
                }))
              : [],
            promoCode,
            type,
            receivingAddress: address,
            receivingRestaurant: restaurant,
            promotionProduct: promotionProductId,
            customerCount: 1,
            customer: customer
              ? {
                  name: customer?.fullName?.firstName || '',
                  phone: customer?.phone || '',
                }
              : null,
          },
        },
      };
    },
  }),
  targets: {
    validate: validateFx,
    promotionProduct: $promotionProduct,
  },
});

sample({
  clock: repeatOrderFx.doneData,
  fn: ({ useCustomerCutleries }) => useCustomerCutleries,
  target: $useCustomerCutleries,
});

// sample({
//   clock: getOrderLongPollFx.doneData,
//   filter: (result) =>
//     !Boolean(
//       result.order.status === 'CANCELLED' || result.order.status === 'DONE',
//     ) && FELocalStorageUtil.getLastOrderId.lastOrderId === result.order.id,
//   fn: (res) => {
//     return {
//       id: res.order.id,
//       timeout: 25,
//     };
//   },
//   target: getOrderLongPollFx,
// });

// sample({
//   clock: getOrderLongPollFx.doneData,
//   fn: (res) => {
//     if (
//       !Boolean(res.order.status === 'CANCELLED' || res.order.status === 'DONE')
//     )
//       return res;
//     return null;
//   },
//   target: $lastOrder,
// });

// getOrderLongPollFx.doneData.watch((res) => {
//   if (res.order.status === 'CANCELLED' || res.order.status === 'DONE')
//     FELocalStorageUtil.deleteLastOrder();
// });

sample({
  clock: [
    validateFx.doneData,
    setPromocodeFx.doneData,
    addDishToCartFx.doneData,
    addConstructorToCartFx.doneData,
  ],
  filter: ({ response: { problems } }) =>
    !!filterBlockingProblems(problems).length,
  fn: ({ response: { problems } }) => {
    return filterBlockingProblems(problems).map((problem) => ({
      title: problem.title,
      message: problem.message,
      actionType: getProblemActionType[problem.reason as ProblemsToShowModel],
      buttonText:
        getButtonText[
          getProblemActionType[problem.reason as ProblemsToShowModel]
        ],
    }));
  },
  target: $validationErrors,
});

sample({
  clock: [
    validateFx.failData,
    setPromocodeFx.failData,
    addDishToCartFx.failData,
    addConstructorToCartFx.failData,
  ],
  source: $validationErrors,
  fn: (errors, response) => {
    const error = response.errors && response.errors[0];

    return [
      ...errors,
      {
        title: error
          ? error.presentationData?.title || t('cart.validationErrors.title')
          : t('cart.validationErrors.title'),
        message: error
          ? error.presentationData?.message ||
            t('cart.validationErrors.message')
          : t('cart.validationErrors.message'),
        buttonText: t('cart.validationErrors.buttonText'),
        actionType: 'hide',
      } as ValidationError,
    ];
  },
  target: $validationErrors,
});

sample({
  clock: hideValidationError,
  source: $validationErrors,
  fn: (errors, { index }) => {
    const newErrors = [...errors];
    newErrors.splice(index, 1);
    return newErrors;
  },
  target: $validationErrors,
});

sample({
  clock: [
    validateFx.failData,
    setPromocodeFx.failData,
    addDishToCartFx.failData,
    addConstructorToCartFx.failData,
  ],
  fn: () => true,
  target: $hasCriticalCartError,
});
sample({
  clock: [
    validateFx.doneData,
    setPromocodeFx.doneData,
    addDishToCartFx.doneData,
    addConstructorToCartFx.doneData,
  ],
  fn: () => false,
  target: $hasCriticalCartError,
});

reset({
  clock: clearCart,
  target: [
    $dishes,
    $constructors,
    $cutleries,
    $useCustomerCutleries,
    $timeTo,
    $urgent,
    $promoCode,
    $promotion,
    $promotionProduct,
    $warning,
    $longDeliveryWarning,
    $problems,
    $price,
    $validationErrors,
    $hasCriticalCartError,
    $createdOrderNotificationStore,
  ],
});
