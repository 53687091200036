import { useNavigate } from 'react-router-dom';
import { useLinks } from 'hooks/router';
import { useData } from './lib/useData';
import { QRCodeContainer } from './QRCodeContainet';
import { BonusGroup } from './BonusGroup';
import clsx from 'clsx';
import style from './CustomerCard.module.css';
import { FEButton } from 'ui-kit/components';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'ui-kit/hooks';
import { useUnit } from 'effector-react';
import { logOutUser } from 'domains/profile';
import { CodeModal } from './CodeModal';
import { useState } from 'react';

export const CustomerCard = () => {
  const { t } = useTranslation();

  const [isOpenCode, setIsOpenCode] = useState(false);

  const is1024 = useMediaQuery('(min-width: 1024px)');

  const is500 = useMediaQuery('(min-width: 500px)');

  const navigate = useNavigate();

  const { phone, name, isLoyaltyProgramAvailable, bonusAccountBalance } =
    useData();

  const isEnoughCustomerData = !!name;

  const { editProfileLink } = useLinks();

  const handleLogOut = useUnit(logOutUser);

  return (
    <>
      <div className={style.card}>
        <div className={style.customerData}>
          {name && (
            <p
              className={clsx(is500 ? 'title' : 'headline', style.profileName)}
            >
              {name}
            </p>
          )}

          <p
            is-enough-customer-data={isEnoughCustomerData.toString()}
            className={clsx(
              isEnoughCustomerData ? 'text-1' : is500 ? 'title' : 'headline',
              style.profilePhone,
            )}
          >
            {phone}
          </p>

          <button
            className={style.editProfileButton}
            onClick={() => navigate(editProfileLink)}
          />
        </div>

        <div
          className={style.loyaltyProgramContainer}
          onClick={() => setIsOpenCode(true)}
        >
          {isLoyaltyProgramAvailable && isEnoughCustomerData && (
            <BonusGroup balance={bonusAccountBalance ?? 0} />
          )}

          <QRCodeContainer
            phone={phone}
            isEnoughCustomerData={isEnoughCustomerData}
            isLoyaltyProgramAvailable={isLoyaltyProgramAvailable}
          />
        </div>

        {is1024 && (
          <FEButton
            className={style.logOutButton}
            onClick={handleLogOut}
            type='secondary'
          >
            {t('Выйти')}
          </FEButton>
        )}
      </div>
      <CodeModal
        onClose={() => setIsOpenCode(false)}
        open={isOpenCode}
        phone={phone}
      />
    </>
  );
};
