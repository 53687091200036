import { CurrencySymbol, PaymentType } from 'models';
import {
  FEButton,
  FEMultilineField,
  FEPhoneField,
  FETextField,
} from 'components';
import { OrderingProps } from './props';
import style from './style.module.css';
import { BonusPicker } from './components';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMG_IC_24_BONUS } from 'ui-kit/images';
import { PaymentTypesPicker } from './components/PaymentTypesPicker';
import { FEHeadersCookiesUtil } from 'utils';

const Ordering = ({
  name,
  onChangeName,
  phone,
  onChangePhone,
  comment,
  onChangeComment,
  paymentType,
  onChangePaymentType,
  bonusAmount,
  onChangeBonusAmount,
  changeFrom,
  onChangeChangeFrom,
  paymentTypes,
  user,
  bonusAccount,
  price,
  onCreateOrder,
  isValidating,
  orderType,
  title,
  onArrowBackClick,
  isDesktop,
  onClose,
  createOrderData,
  features,
  currency,
  countries,
  country,
  changeCountryCode,
  onChangePaymentCardId,
  paymentCardId,
  type,
}: OrderingProps & {
  currency: CurrencySymbol;
  paymentCardId: string | null;
}) => {
  const { t } = useTranslation();
  const [showErrors, setShowErrors] = React.useState<boolean>(false);
  const [isValidPhone, setIsValidPhone] = React.useState<boolean>(true);

  return (
    <>
      <div className={style.ordering}>
        <div className={style.orderingContent}>
          {isDesktop ? (
            <div className={style.header}>
              <button
                tabIndex={0}
                className='button-arrowBack'
                onClick={(e) => {
                  onArrowBackClick();
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
              <h3 className='title'>{title}</h3>
            </div>
          ) : (
            <div className={style.header}>
              <div className={style.side}>
                <button
                  tabIndex={0}
                  className='button-arrowBack'
                  onClick={(e) => {
                    onArrowBackClick();
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
                <h3 className='title'>{title}</h3>
              </div>
              <button
                tabIndex={0}
                className='closeButton-mobile'
                onClick={(e) => {
                  onClose();
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </div>
          )}
          <div className={style.inputs}>
            <div className={style.input}>
              <FETextField
                disabled={!!user?.fullName?.firstName || isValidating}
                hasError={!name && showErrors}
                label={t('cart.ordering.form.nameLabel')}
                value={name}
                onChange={(e) => onChangeName(e.target.value || null)}
              />
            </div>
            <div className={style.input}>
              <FEPhoneField
                onValid={(valid) => setIsValidPhone(valid)}
                currentCountry={
                  FEHeadersCookiesUtil.getCountryCode || country || null
                }
                countries={countries}
                disabled={!!user?.phone || isValidating}
                hasError={(!phone || !isValidPhone) && showErrors}
                value={phone}
                onChangeCountry={changeCountryCode}
                onChange={(value) => onChangePhone(value || null)}
              />
            </div>
            <div className={style.input}>
              <FEMultilineField
                disabled={isValidating}
                hasSelectionEffect={false}
                maxLength={200}
                label={t('cart.ordering.form.commentLabel')}
                value={comment}
                onClear={() => onChangeComment(null)}
                onChange={(e) => onChangeComment(e.target.value || null)}
              />
            </div>
            <div>
              <p className={clsx('headline', style.paymentTypeTitle)}>
                {t('Способ оплаты')}
              </p>
              <PaymentTypesPicker
                paymentCardId={paymentCardId}
                disabled={isValidating}
                orderType={orderType}
                hasError={paymentType === null && showErrors}
                paymentTypes={paymentTypes.filter(
                  (paymentType) =>
                    (paymentType as PaymentType) !== 'BONUS' &&
                    (paymentType as PaymentType) !== 'SBERPAY',
                )}
                paymentType={paymentType}
                onChange={(paymentType, paymentCardId) => {
                  onChangePaymentCardId(paymentCardId);
                  onChangePaymentType(paymentType);
                }}
              />
            </div>
            {/* <FEPaymentTypePicker
              disabled={isValidating}
              orderType={orderType}
              hasError={paymentType === null && showErrors}
              paymentTypes={paymentTypes.filter(
                (paymentType) =>
                  (paymentType as PaymentType) !== 'BONUS' &&
                  (paymentType as PaymentType) !== 'SBERPAY',
              )}
              value={paymentType}
              onChange={(type) => onChangePaymentType(type)}
            /> */}
            <div className={style.input}>
              {paymentType === 'CASH' && (
                <FETextField
                  disabled={isValidating}
                  label={t('cart.ordering.form.changeLabel')}
                  type='number'
                  value={changeFrom?.toString() || ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (Boolean(value)) onChangeChangeFrom(Number(value));
                    else onChangeChangeFrom(null);
                  }}
                />
              )}
            </div>
          </div>
          {!!bonusAccount?.balance && features.includes('LOYALTY_PROGRAM') && (
            <div className={style.bonusContainer}>
              <BonusPicker
                orderTotal={price?.total ?? 0}
                bonusName={t('cart.ordering.components.bonusPicker.title')}
                balance={bonusAccount!.balance}
                amount={bonusAmount}
                onChange={(v) => onChangeBonusAmount(v)}
              />
            </div>
          )}
          <div className={style.price}>
            <div className={style.priceItem}>
              <p className='text-1'>{t('cart.ordering.price.cart')}</p>
              <p className='text-1'>{`${price?.cart} ${currency}`}</p>
            </div>
            {!!price?.discount && (
              <div className={style.priceItem}>
                <p className='text-1'>{t('cart.ordering.price.discount')}</p>
                <p className='text-1'>{`${price?.discount} ${currency}`}</p>
              </div>
            )}
            {!!bonusAmount && features.includes('LOYALTY_PROGRAM') && (
              <div className={style.priceItem}>
                <p className='text-1'>{t('cart.ordering.price.caviar')}</p>
                <div className={style.caviar}>
                  <p className='text-1'>{bonusAmount}</p>
                  <img src={IMG_IC_24_BONUS} alt='' />
                </div>
              </div>
            )}
            <div className={style.priceItem}>
              <p className='text-1'>{t('cart.ordering.price.delivery')}</p>
              <p className='text-1'>{`${price?.delivery || 0} ${currency}`}</p>
            </div>
            <div className={clsx(style.priceItem, style.totalPrice)}>
              <p className='text-1'>{t('cart.ordering.price.total')}</p>
              <p className='text-1'>{`${
                (price?.total ?? 0) - (bonusAmount ?? 0)
              } ${currency}`}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={style.submit}>
        <FEButton
          disabled={isValidating}
          showLoader={isValidating}
          onClick={() => {
            setShowErrors(true);
            if (
              !!phone &&
              paymentType !== null &&
              !!name &&
              !isValidating &&
              isValidPhone
            ) {
              const page =
                paymentType === 'CARD' || paymentType === 'SBP'
                  ? window.open('', '_blank')
                  : null;

              onCreateOrder({ ...createOrderData, type }).then(
                (res) => {
                  if (res.url && page) {
                    page.document.location = res.url;
                  }
                },
                () => {
                  if (page) page.close();
                },
              );
            }
          }}
        >
          {t('cart.ordering.submit')}
        </FEButton>
      </div>
    </>
  );
};

export default Ordering;
